import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import AsyncComputed from "vue-async-computed"

window.$googleConfig = {
  // dev-server
  // apiKey: "AIzaSyCIiP0o_Dz9tBmZFYBxM444Jw58QiIQyrw",
  // authDomain: "hachimenroppi-dev.firebaseapp.com",
  // projectId: "hachimenroppi-dev",
  // storageBucket: "hachimenroppi-dev.appspot.com",
  // messagingSenderId: "888180845987",
  // appId: "1:888180845987:web:414737b244251e6c7d7e16",
  // clientId: "888180845987-hmhtlvqlt6np0ir551cnavoocvra283e.apps.googleusercontent.com"

  // production-server
  apiKey: "AIzaSyC7fxHeMEEiuFuNuB8WbUZk2DMZ7mNcITg",
  authDomain: "livla9.abyssalis-aquos.net",
  projectId: "livla9",
  storageBucket: "livla9.appspot.com",
  messagingSenderId: "178756182000",
  appId: "1:178756182000:web:ebbee1615a6374cd568735",
  measurementId: "G-EHRR16LK31",
  // clientId: ここに本番環境GCPのクライアントIDを入れる
};

// Initialize Firebase
Vue.prototype.$app = firebase.initializeApp(window.$googleConfig);
Vue.prototype.$db = getFirestore(Vue.prototype.$app);
Vue.prototype.$storage = getStorage(Vue.prototype.$app);

Vue.config.productionTip = false
Vue.use(AsyncComputed);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
