<template>
    <v-main>
      <div class="pa-4">
        <div class="text-h5 ma-3">次回の卓予定</div>
        <v-card elevation="3" class="mt-2 mb-12" :class="autoDark($vuetify.theme.themes.light.primary)? 'white--text': 'black--text'" color="primary">
          <v-card-title>
            {{nextString}}
          </v-card-title>
        </v-card>
      </div>
      <div class="pa-4">
        <div class="text-h5 ma-3">今週の予定</div>
        <Week_calendar />
      </div>
    </v-main>
</template>

<script>
import Week_calendar from "./week-calendar.vue";
import { collection, getDocs } from 'firebase/firestore/dist'
import { where, orderBy, limit, query } from 'firebase/firestore/dist';
import{ getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";

export default {

  name: "top",
  components: {
    Week_calendar,
  },

  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user.uid;
        this.getSchedule();
      } else {
        this.$router.push("/login");
      }
    });
  },

  methods: {
  async getSchedule() {
    const scheduleRef = collection(this.$db, "schedule");
        const q = query(scheduleRef, where("user", "==", this.user), where("start", ">=", new Date()), where("category", "==", "TRPG予定"), orderBy("start", "asc"), limit(1));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          this.$set(this.nextevent, "name", doc.data().name);
          this.$set(this.nextevent, "start", doc.data().start.toDate());
        });
        },
    autoDark(color) {
            if (!color) return false;
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        }
  },

  data() {
    return {
      nextevent: {},
    }
  },

  computed: {
    nextString: function() {
      var result = "";
      if (this.nextevent.name) {
        result = this.nextevent.name + "　　" + moment(this.nextevent.start).format("MM/DD HH:mm") + "〜"
      } else {
        result = "現在予定されている卓はありません。"
      }
      return result;
    }
  }

};
</script>
