<template>
  <v-app>
  <div>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        locale="ja-JP"
        type="week"
        :events="events"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        :event-text-color="autoEventColor"
        @change="getEvents"
      >
      <template v-slot:event=event><div>
          <span class="pl-1"><strong>{{event.event.name}}</strong></span><br>
          <span class="pl-1">{{event.eventParsed.start.time}}〜{{event.eventParsed.end.time}}</span>
      </div></template></v-calendar>
    </v-sheet>
  </div>
</v-app>
</template>

<script>
import { collection, getDocs } from 'firebase/firestore/dist'
import { where, query } from 'firebase/firestore/dist';
import moment from "moment";
import{ getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: "Week_calendar",

  mounted() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        this.user = user.uid;
        if (this.user) {
            this.getEvents();
        }
        });
    const scrollArea = document.querySelector("div.v-calendar-daily__scroll-area");
    scrollArea.scrollTop = scrollArea.scrollHeight;
  },

  methods: {
    getEventColor (event) {
      return event.color;
    },

    async getEvents() {
      const weekstart = moment().day(0).hour(0).minute(0).second(0).toDate();
      const weekend = moment().day(6).hour(23).minute(59).second(59).toDate();
      this.events = [];

      const scheduleRef = collection(this.$db, "schedule");
      const q = query(scheduleRef, where("user", "==", this.user), where("start", ">=", weekstart), where("start", "<=", weekend));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        var event = doc.data();
        event.start = moment(event.start.toDate()).format("YYYY-MM-DD HH:mm");
        event.end = moment(event.end.toDate()).format("YYYY-MM-DD HH:mm");
        event.timed = false;
        this.events.push(event);
      });

    },
    autoEventColor(event) {
            const color = event.color;
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return "grey darken-3";
            else return "white";
        }
  },

  data() {
    return {
      events: this.events,
    }
  }

}

</script>
