<template>
    <v-main>
        <v-sheet>
            <div class="text-h5 ma-3">My予定表</div>
        </v-sheet>
        <v-sheet class="d-flex" height="40">
            <v-btn icon class="ma-2"
            @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left-circle</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <span class="text-h6">{{value.split("-")[0] + "年" + value.split("-")[1] + "月"}}</span>
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2"
            @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right-circle</v-icon>
            </v-btn>
        </v-sheet>
        <v-sheet class="ma-3" height="800">
            <v-calendar ref="calendar"
            type="month"
            locale="ja-JP"
            v-model="value"
            :events="events"
            :event-name="eventNameFormat"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :event-text-color="autoEventColor"
            @change="getEvents"
            @click:event="showEvent"
            @click:date="showCreate">
                <template v-slot:event=event><div>
                    <span class="pl-1"><strong>{{event.eventParsed.start.time}}</strong> {{event.event.name}}</span>
                </div></template>
            </v-calendar>
            <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="250px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              :dark="autoDark(selectedEvent.color)"
            >
              <v-toolbar-title v-text="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showEdit($event)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="showDuplicate($event)">
                <v-icon>mdi-plus-box-multiple</v-icon>
              </v-btn>
              <v-btn icon @click="deleteEvent">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-subtitle>
              <span v-text="selectedPeriod"></span>
            </v-card-subtitle>
            <v-card-text>
                <div class="mb-1"><span v-text="selectedCategory"></span></div>
                <span class="prewrap" v-text="selectedDetail"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="selectedOpen = false"
              >
                キャンセル
              </v-btn>
              <v-btn text v-if="selectedEvent.group" @click="$router.push('/group/' + selectedEvent.group)">卓ページへ</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog v-model="dialog" persistent>
            <v-card>
                <v-toolbar
              :color="color"
              :dark="autoDark(this.color)" 
            >
                <v-toolbar-title>
                    <span class="text-h5" v-text="dialogMessage"></span>
                </v-toolbar-title>
                </v-toolbar>
                <v-container class="pa-4">
                    <v-form v-model="valid" ref="form">
                        <v-expansion-panels v-if="dialogMessage == '新しい予定'" v-model="panel">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    パレットから入力
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-chip v-for="chip in palette" :key="chip.key" v-text="chip.name" :color="chip.color" text-color="white"
                @click="fillChip($event, chip)" :name="chip.key" class="ma-1"></v-chip>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    定期予定の入力
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    入力する曜日<br>
                                    <v-row justify="start">
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="red darken-2" label="日" value="0"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue-gray" label="月" value="1"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue-gray" label="火" value="2"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue-gray" label="水" value="3"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue-gray" label="木" value="4"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue-gray" label="金" value="5"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3" sm="1">
                                    <v-checkbox v-model="spanday" color="blue darken-2" label="土" value="6"></v-checkbox>
                                    </v-col>
                                    </v-row>
                                    終了日
                                    <v-menu v-model="spandateOpen" :close-on-content-click="false" offset-y max-width="290">
                                        <template v-slot:activator="{on, attrs}">
                                        <v-text-field label="日付" v-model="spandate" v-bind="attrs" v-on="on" :rules="spanday.length == 0 ? dateInrequiredRules : dateRules" readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-if="spandateOpen" v-model="spandate" full-width><v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="spandateOpen = false">決定</v-btn></v-row></v-date-picker>
                                    </v-menu>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-text-field label="イベント名" v-model="name" required :rules="requireRules" ref="name"></v-text-field>
                        <v-menu v-model="dateOpen" :close-on-content-click="false" offset-y max-width="290">
                            <template v-slot:activator="{on, attrs}">
                            <v-text-field label="日付" v-model="date" v-bind="attrs" v-on="on" required :rules="dateRules" readonly></v-text-field>
                            </template>
                            <v-date-picker v-if="dateOpen" v-model="date" full-width>
                                <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="dateOpen = false">決定</v-btn></v-row>
                            </v-date-picker>
                        </v-menu>
                        <v-row class="px-3">
                            <v-menu v-model="startOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                            <v-text-field label="開始" v-model="start" v-bind="attrs" v-on="on" required :rules="timeRules" readonly></v-text-field>
                            </template>
                            <v-time-picker v-if="startOpen" full-width v-model="start" format="24hr" scrollable :allowed-minutes="minStep">
                                <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="startOpen = false">決定</v-btn></v-row>
                            </v-time-picker>
                        </v-menu>
                        〜
                        <v-menu v-model="endOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                            <v-text-field label="終了" v-model="end" v-bind="attrs" v-on="on" required :rules="timeRules" readonly></v-text-field>
                            </template>
                            <v-time-picker v-if="endOpen" full-width v-model="end" format="24hr" scrollable :allowed-minutes="minStep">
                                <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="endOpen = false">決定</v-btn></v-row>
                            </v-time-picker>
                        </v-menu>
                        <v-menu v-model="colorOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar :color="color" size="40" class="ma-2" v-bind="attrs" v-on="on"></v-avatar>
                            </template>
                            <v-color-picker v-model="color" hide-mode-switch mode="hexa" show-swatches></v-color-picker>
                        </v-menu>
                        </v-row>
                        <v-select v-model="category" :items="categories" label="種別" required :rules="requireRules"></v-select>
                        <v-textarea label="詳細" v-model="detail" ref="detail"></v-textarea>
                        <v-row class="pa-3">
                            <v-spacer></v-spacer>
                            <v-btn @click="cancelCreate">キャンセル</v-btn>
                            <v-btn v-if="dialogMessage == '予定の複製'" class="mx-2" @click="eventPalette">パレット化</v-btn>
                            <v-btn color="primary" class="mx-2" @click="submitEvent">保存</v-btn>
                        </v-row>
                        
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        </v-sheet>
        <v-sheet max-height="200px" class="ma-3 mt-12">
                <v-row class="px-3"><span class="text-h5 ma-3 mb-6">パレット編集</span><v-spacer></v-spacer>
                    <v-btn icon @click="showAddPalette">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-row>
            <div>
                <v-chip v-for="chip in palette" :key="chip.key" v-text="chip.name" :color="chip.color" :dark="autoDark(chip.color)"
                @click="selectChip($event, chip)" :name="chip.key" class="ma-1"></v-chip>
            </div>
        <v-menu
          v-model="chipOpen"
          :close-on-content-click="false"
          :activator="selectedChip"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="250px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-text="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showEditPalette($event)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deletePalette">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-subtitle>
              <span v-text="chipPeriod"></span>
            </v-card-subtitle>
            <v-card-text>
                <span v-text="selectedCategory"></span><br>
                <span class="prewrap" v-text="selectedDetail"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog v-model="addPalette" persistent>
            <v-card>
                <v-toolbar
              :color="color"
              :dark="autoDark(color)"
            >
                <v-toolbar-title>
                    <span class="text-h5" v-text="dialogMessage"></span>
                </v-toolbar-title>
                </v-toolbar>
                <v-container class="pa-4">
                    <v-form v-model="valid" ref="paletteForm">
                        <v-text-field label="イベント名" v-model="name" required :rules="requireRules"></v-text-field>
                        <v-row class="px-3">
                            <v-menu v-model="paletteStartOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                            <v-text-field label="開始" v-model="start" v-bind="attrs" v-on="on" required :rules="timeRules" readonly></v-text-field>
                            </template>
                            <v-time-picker full-width v-if="paletteStartOpen" v-model="start" format="24hr" scrollable :allowed-minutes="minStep">
                                <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="paletteStartOpen = false">決定</v-btn></v-row>
                            </v-time-picker>
                        </v-menu>
                        〜
                        <v-menu v-model="paletteEndOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                            <v-text-field label="終了" v-model="end" v-bind="attrs" v-on="on" required :rules="timeRules" readonly></v-text-field>
                            </template>
                            <v-time-picker full-width v-if="paletteEndOpen" v-model="end" format="24hr" scrollable :allowed-minutes="minStep">
                                <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="paletteEndOpen = false">決定</v-btn></v-row>
                            </v-time-picker>
                        </v-menu>
                        <v-menu v-model="paletteColorOpen" :close-on-content-click="false">
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar :color="color" size="40" class="ma-2" v-bind="attrs" v-on="on"></v-avatar>
                            </template>
                            <v-color-picker v-model="color" hide-mode-switch mode="hexa" show-swatches></v-color-picker>
                        </v-menu>
                        </v-row>
                        <v-select v-model="category" :items="categories" label="種別" required :rules="requireRules"></v-select>
                        <v-textarea label="詳細" v-model="detail"></v-textarea>
                        <v-row class="pa-3">
                            <v-spacer></v-spacer>
                            <v-btn @click="addPalette = false">キャンセル</v-btn>
                            <v-btn color="primary" class="mx-2" @click="submitPalette">保存</v-btn>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        </v-sheet>
    </v-main>
</template>

<script>
import { collection, doc, getDocs, setDoc, addDoc, deleteDoc, writeBatch } from 'firebase/firestore/dist'
import { where, query } from 'firebase/firestore/dist';
import moment from "moment";
import{ getAuth, onAuthStateChanged } from "firebase/auth";

export default {
    mounted() {
        const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        if (user) {
            this.user = user.uid;
            this.getNowSchedule();
        } else {
            this.$router.push("/login");
        }
        });
        this.color = this.$vuetify.theme.themes.light.primary
    },

    methods: {
        getEventColor (event) {
            return event.color;
        },

        async getEvents(period) {
            if (!this.user) return;
            const min = new Date(`${period.start.date}T00:00:00`);
            const max = new Date(`${period.end.date}T23:59:59`);
            this.events = [];
            const scheduleRef = collection(this.$db, "schedule");
            const q = query(scheduleRef, where("user", "==", this.user), where("start", ">=", min), where("start", "<=", max));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(doc => {
                var event = doc.data();
                event.key = doc.id;
                event.start = moment(event.start.toDate()).format("YYYY-MM-DD HH:mm");
                event.end = moment(event.end.toDate()).format("YYYY-MM-DD HH:mm");
                event.timed = false;
                this.events.push(event);
            });
        },

        async getNowSchedule() {
            const min = moment().startOf("month").toDate();
            const max = moment().startOf("month").add(1, "months").toDate();
            this.events = [];
            this.palette = [];
            const scheduleRef = collection(this.$db, "schedule");
            const q = query(scheduleRef, where("user", "==", this.user), where("start", ">=", min), where("start", "<=", max));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(doc => {
                var event = doc.data();
                event.key = doc.id;
                event.start = moment(event.start.toDate()).format("YYYY-MM-DD HH:mm");
                event.end = moment(event.end.toDate()).format("YYYY-MM-DD HH:mm");
                event.timed = false;
                this.events.push(event);
            });
            const paletteRef = collection(this.$db, "palette");
            const p = query(paletteRef, where("user", "==", this.user));
            const paletteSnapshot = await getDocs(p);
            paletteSnapshot.forEach(doc => {
                var event = doc.data();
                event.key = doc.id;
                const startArr = event.start.split(":");
                const endArr = event.end.split(":");
                event.start = startArr[0] + ":" + startArr[1];
                event.end = endArr[0] + ":" + endArr[1];
                this.palette.push(event);
            })
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }
            if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            nativeEvent.stopPropagation();
        },
         
        showCreate(target) {
            this.dialogMessage = "新しい予定",
            this.selectedEvent = {};
            this.dialog = true;
            this.name = "";
            this.date = target.date;
            this.start = "";
            this.end = "";
            this.color = this.primaryColor;
            this.category = "";
            this.past = target.past;
            this.detail = "";
            this.panel = [];
            this.spanday = [];
            this.spandate = "";
            this.$nextTick(() => this.$refs.name.focus());
            this.$nextTick(() => this.$refs.form.resetValidation());
        },

        showEdit(event) {
            this.selectedOpen = false;
            this.dialogMessage = "予定の編集";
            this.name = this.selectedEvent.name;
            this.date = moment(this.selectedEvent.start).format("YYYY-MM-DD");
            this.start = moment(this.selectedEvent.start).format("HH:mm");
            this.end = moment(this.selectedEvent.end).format("HH:mm");
            this.color = this.selectedEvent.color;
            this.category = this.selectedEvent.category;
            this.detail = this.selectedEvent.detail;
            this.dialog = true;
            event.stopPropagation();
            this.$nextTick(() => this.$refs.form.resetValidation());
        },

        showDuplicate(event) {
            this.selectedOpen = false;
            this.dialogMessage = "予定の複製";
            this.name = this.selectedEvent.name;
            this.date = moment(this.selectedEvent.start).format("YYYY-MM-DD");
            this.start = moment(this.selectedEvent.start).format("HH:mm");
            this.end = moment(this.selectedEvent.end).format("HH:mm");
            this.color = this.selectedEvent.color;
            this.category = this.selectedEvent.category;
            this.detail = this.selectedEvent.detail;
            this.dialog = true;
            event.stopPropagation();
            this.$nextTick(() => this.$refs.form.resetValidation());
        },

        colorShow() {
            this.colorOpen = true;
        },

        cancelCreate() {
            this.dialog = false;
        },

        async submitEvent() {
            if (this.$refs.form.validate() && this.colorRules(this.color)) {
                if (this.end == "00:00") this.end = "23:59"
                var start = moment(this.date + " " + this.start + ":00");
                var end = moment(this.date + " " + this.end + ":00");
                if (start.isSameOrAfter(end)) {
                    end = end.add(1, "d");
                }
                if (this.spanday.length != 0) {
                    const startDay = start.day();
                    const endDate = moment(this.spandate + " 23:59:00");
                    const batch = writeBatch(this.$db);
                    const collectionRef = collection(this.$db, "schedule");
                    for (var targetDay of this.spanday) {
                        var targetDate = start.clone().day(targetDay);
                        var targetEnd = end.clone().day(targetDay);
                        if (start.isSameOrAfter(end)) {
                            targetEnd = targetEnd.add(1, "d");
                        }
                        if (targetDay < startDay) {
                            targetDate.add(1, "w");
                            targetEnd.add(1, "w");
                        }
                        while (targetDate <= endDate) {
                            const docid = doc(collectionRef).id;
                            batch.set(doc(collectionRef, docid), {
                                name: this.name,
                                start: targetDate.toDate(),
                                end: targetEnd.toDate(),
                                color: this.color,
                                category: this.category,
                                detail: this.detail || "",
                                user: this.user
                            });
                            this.events.push({
                                name: this.name,
                                start: targetDate.format("YYYY-MM-DD HH:mm"),
                                end: targetEnd.format("YYYY-MM-DD HH:mm"),
                                color: this.color,
                                category: this.category,
                                detail: this.detail || "",
                                key: docid,
                                timed: false,
                                user:this.user
                            });
                            targetDate.add(1, "w");
                            targetEnd.add(1, "w");
                        }
                    }
                    await batch.commit();
                    this.dialog = false;
                    return;
                }
                if (Object.keys(this.selectedEvent).length && this.dialogMessage == '予定の編集') {
                    await setDoc(doc(this.$db, "schedule", this.selectedEvent.key), {
                        name: this.name,
                        start: start.toDate(),
                        end: end.toDate(),
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "",
                        user: this.user
                    });
                    const key = this.events.findIndex((e) => e.key == this.selectedEvent.key);
                    this.$set(this.events[key], "name", this.name);
                    this.$set(this.events[key], "start", start.format("YYYY-MM-DD HH:mm"));
                    this.$set(this.events[key], "end", end.format("YYYY-MM-DD HH:mm"));
                    this.$set(this.events[key], "color", this.color);
                    this.$set(this.events[key], "category", this.category);
                    this.$set(this.events[key], "detail", this.detail || "");
                    this.$set(this.events[key], "key", this.selectedEvent.key);
                    this.$set(this.events[key], "timed", false);
                    this.$set(this.events[key], "user", this.user);
                } else {
                    var docu = await addDoc(collection(this.$db, "schedule"), {
                        name: this.name,
                        start: start.toDate(),
                        end: end.toDate(),
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "",
                        user: this.user
                    });
                    this.events.push({
                        name: this.name,
                        start: start.format("YYYY-MM-DD HH:mm"),
                        end: end.format("YYYY-MM-DD HH:mm"),
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "",
                        key: docu.id,
                        timed: false,
                        user:this.user,
                        group: null
                    })
                }
                this.dialog = false;
            }
        },

        async eventPalette() {
            if (this.$refs.form.validate() && this.colorRules(this.color)) {
            if (this.end == "00:00") this.end = "23:59"
            var start = this.start + ":00";
            var end = this.end + ":00";
                const doc = await addDoc(collection(this.$db, "palette"), {
                    name: this.name,
                    start: start,
                    end: end,
                    color: this.color,
                    category: this.category,
                    detail: this.detail || "",
                    user: this.user
                });
            this.palette.push({
                name: this.name,
                start: this.start,
                end: this.end,
                color: this.color,
                category: this.category,
                detail: this.detail || "",
                key: doc.id
            });                
                this.dialog = false;
            }
        },

        async deleteEvent() {
            var yes = confirm("イベントを削除します。よろしいですか？");
            if (yes) {
                await deleteDoc(doc(this.$db, "schedule", this.selectedEvent.key));
                this.events.splice(this.events.findIndex((e) => e.key == this.selectedEvent.key), 1);
            } 
            this.selectedOpen = false;
        },

        showAddPalette() {
            this.dialogMessage = "新しい予定テンプレート",
            this.selectedEvent = {};
            this.addPalette = true;
            this.name = "";
            this.start = "";
            this.end = "";
            this.color = this.primaryColor;
            this.category = "";
            this.detail = "";
            this.$nextTick(() => this.$refs.paletteForm.resetValidation());
        },

        selectChip(event, chip) {
            const open = () => {
            this.selectedEvent = chip;
            this.selectedChip = "[name='" + chip.key + "']";
            requestAnimationFrame(() => requestAnimationFrame(() => this.chipOpen = true))
            }

            if (this.chipOpen) {
            this.chipOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            event.stopPropagation()
        },

        showEditPalette(event) {
            this.dialogMessage = "予定テンプレートの編集",
            this.name = this.selectedEvent.name;
            this.start = this.selectedEvent.start;
            this.end = this.selectedEvent.end;
            this.color = this.selectedEvent.color;
            this.category = this.selectedEvent.category;
            this.detail = this.selectedEvent.detail;
            this.addPalette = true;
            event.stopPropagation();
            this.$nextTick(() => this.$refs.paletteForm.resetValidation());
        },

        async deletePalette() {
            var yes = confirm("予定テンプレートを削除します。よろしいですか？");
            if (yes) {
                await deleteDoc(doc(this.$db, "palette", this.selectedEvent.key));
                this.palette.splice(this.palette.findIndex((e) => e.key == this.selectedEvent.key), 1);
            } 
            this.chipOpen = false;
        },

        async submitPalette() {
            if (this.$refs.paletteForm.validate() && this.colorRules(this.color)) {
                if (this.end == "00:00") this.end = "23:59"
                var start = this.start + ":00";
                var end = this.end + ":00";
                if (Object.keys(this.selectedEvent).length) {
                    await setDoc(doc(this.$db, "palette", this.selectedEvent.key), {
                        name: this.name,
                        start: start,
                        end: end,
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "",
                        user: this.user
                    });
                    this.$set(this.palette, this.palette.findIndex((e) => e.key == this.selectedEvent.key), {
                        name: this.name,
                        start: this.start,
                        end: this.end,
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "", 
                        key: this.selectedEvent.key,
                        user: this.user
                    });
                } else {
                    const doc = await addDoc(collection(this.$db, "palette"), {
                        name: this.name,
                        start: start,
                        end: end,
                        color: this.color,
                        category: this.category,
                        detail: this.detail || "",
                        user: this.user
                    });
                this.palette.push({
                    name: this.name,
                    start: this.start,
                    end: this.end,
                    color: this.color,
                    category: this.category,
                    detail: this.detail || "",
                    key: doc.id
                });
                }
                
                this.addPalette = false;
            }
        },

        fillChip(event, chip) {
            this.name = chip.name;
            this.start = chip.start;
            this.end = chip.end;
            this.color = chip.color;
            this.category = chip.category;
            this.detail = chip.detail;
            this.$refs.detail.focus();
            event.stopPropagation();
        },
        autoDark(color) {
            if (!color) return "";
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
        autoEventColor(event) {
            const color = event.color;
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return "grey darken-3";
            else return "white";
        },
        eventNameFormat(event) {
            return event.start.time + " " + event.input.name;
        }
    },

    data() {
        return {
            events: [],
            palette: [],
            value: moment().format("YYYY-MM-DD"),
            selectedEvent: {},
            selectedElement: null,
            selectedChip: null,
            selectedOpen: false,
            spandateOpen: false,
            dateOpen: false,
            startOpen: false,
            endOpen: false,
            colorOpen: false,
            dialog: false,
            paletteOpen: false,
            chipOpen: false,
            addPalette: false,
            valid: false,
            paletteStartOpen: false,
            paletteEndOpen: false,
            paletteColorOpen: false,
            categories: ["リアル予定", "TRPG予定", "その他予定"],
            spanday: [],
            spandate: "",
            dialogMessage: "新しい予定",
            name: "",
            date: "",
            start: "",
            end: "",
            color: this.$vuetify.theme.themes.light.primary,
            category: "",
            detail: "",
            panel: [],
            past: false,
            requireRules: [ v => !!v || `入力してください`],
            dateRules: [ v => /^\d{4}-\d{2}-\d{2}$/.test(v) || `日付の形式が不正です`, v => moment(v).isValid() || `日付の形式が不正です`],
            dateInrequiredRules: [v => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || `日付の形式が不正です`, v => !v || moment(v).isValid() || `日付の形式が不正です`],
            timeRules: [ v => /^\d\d?:\d{2}/.test(v) || `時刻の形式が不正です`],
            colorRules: v => /^#[0123456789ABCDEFabcdef]{6}$/.test(v),
        }
    },

    computed: {
        selectedPeriod: function() {
            return Object.keys(this.selectedEvent).length ? moment(this.selectedEvent.start).format("MM月DD日 HH:mm") + "〜" + moment(this.selectedEvent.end).format("HH:mm") : ""
        },
        chipPeriod: function() {
            return Object.keys(this.selectedEvent).length ? this.selectedEvent.start + "〜" + this.selectedEvent.end : "";
        },
        selectedDetail: function() {
            return Object.keys(this.selectedEvent).length ? this.selectedEvent.detail : "";
        },
        selectedCategory: function() {
            return Object.keys(this.selectedEvent).length ? this.selectedEvent.category : "";
        },
        minStep: function() {
            return m => m % 5 === 0;
        },
        primaryColor: function() {
            return this.$vuetify.theme.themes.light.primary;
        },
    }
}
</script>

<style scoped>
.prewrap {
    white-space: pre-wrap;
}
</style>