<template>
    <v-main>
        <div class="text-h5 ma-3">卓の設定</div>
        <v-form ref="form" class="mx-2" @dragover.prevent @dragenter.prevent>
            <v-row class="px-3">
                <v-menu v-model="colorOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar :color="color" size="40" class="my-3 mr-4" v-bind="attrs" v-on="on"></v-avatar>
                            </template>
                            <v-color-picker v-model="color" hide-mode-switch mode="hexa" show-swatches></v-color-picker>
                        </v-menu>
            <v-text-field required v-model="name" label="卓名" ref="name" :rules="requireRules"></v-text-field>
            </v-row>
            <v-row class="px-3">
                <v-file-input label="ヘッダ画像" v-model="image" accept="image/*" prepend-icon="mdi-image-area" :rules="fileRules"></v-file-input>
                <v-img max-height="60" max-width="120" :src="url" class="mx-3">
                            <template v-slot:placeholder><v-sheet height="100%" width="100%" :color="color"></v-sheet></template>
                        </v-img>
                </v-row>
            <v-textarea v-model="detail" label="説明（HTMLタグ使用可）"></v-textarea>
            <v-text-field v-model="room" label="部屋URL"></v-text-field>
            <div class="text-h6 py-4">部屋データの保護</div>
            <v-radio-group v-model="roomSecret" row>
                <v-radio key="password" value="password" label="パスワードを設定" :color="this.color"></v-radio>
                <v-radio key="none" value="none" label="設定しない" :color="this.color"></v-radio>
            </v-radio-group>
            <v-text-field v-if="roomSecret == 'password'" v-model="password" label="パスワード" required
             :type="showpass ? 'text' : 'password'" :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showpass = !showpass"></v-text-field>
            <v-text-field outlined readonly label="招待URL" :value="'https://livla9.abyssalis-aquos.net/group/' + id"></v-text-field>
            <v-checkbox label="キャラシ秘匿" v-model="sheetSecret" :color="this.color"></v-checkbox>
            <v-checkbox label="秘匿メモ" v-model="memoSecret" :color="this.color"></v-checkbox>
            <div class="text-h6 py-4">デフォルト時間帯</div>
            <v-row class="px-3">
                    <v-menu v-model="startOpen" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{on, attrs}">
                    <v-text-field label="開始" v-model="start" v-bind="attrs" v-on="on" :rules="timeRules"></v-text-field>
                    </template>
                    <v-time-picker v-if="startOpen" full-width v-model="start" format="24hr" scrollable :allowed-minutes="minStep">
                        <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="startOpen = false">決定</v-btn></v-row>
                    </v-time-picker>
                </v-menu>
                〜
                <v-menu v-model="endOpen" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{on, attrs}">
                    <v-text-field label="終了" v-model="end" v-bind="attrs" v-on="on" :rules="timeRules"></v-text-field>
                    </template>
                    <v-time-picker v-if="endOpen" full-width v-model="end" format="24hr" scrollable :allowed-minutes="minStep">
                        <v-row class="pa-2"><v-spacer></v-spacer><v-btn text :color="color" @click="endOpen = false">決定</v-btn></v-row>
                    </v-time-picker>
                </v-menu>
            </v-row>
            <div class="text-h6 py-4">予定アイコン</div>
            <v-btn v-for="item in icons" :key="item" icon :color="item == icon ? color : 'grey'" @click="icon = item" x-large><v-icon>{{item}}</v-icon></v-btn>
            <div class="text-h6 py-4">メンバー編集</div>
            <v-list>
                <draggable v-model="member">
                <v-list-item v-for="mem, index in member" :key="mem.id">
                    <v-list-item-avatar :color="mem.color"><v-img :src="mem.icon"></v-img></v-list-item-avatar>
                    <v-list-item-content>{{mem.name}}</v-list-item-content>
                    <v-list-item-action><v-btn icon @click="omitPlayer(index)"><v-icon color="grey">mdi-delete</v-icon></v-btn></v-list-item-action>
                </v-list-item>
                </draggable>
            </v-list>
            <v-checkbox label="完走済み" v-model="complete" :color="color"></v-checkbox>
            <v-row class="pa-3">
                <v-spacer></v-spacer>
                <v-btn color="error" @click="discard">卓の削除</v-btn>
                <v-btn v-if="!isNew" @click="cancel" class="mx-2">キャンセル</v-btn>
                <v-btn color="primary" @click="submit">保存</v-btn>
            </v-row>
        </v-form>
    </v-main>
</template>
<script>
import moment from "moment";
import draggable from "vuedraggable";
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
    components: {
        draggable,
    },
    mounted() {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.user = user.uid;
                this.checkData();
            } else {
                this.$router.push("/login");
            }
        });
    },
    data() {
        return {
            user: "",
            id: "",
            name: "",
            color: this.$vuetify.theme.themes.light.primary,
            detail: "",
            room: "",
            start: "00:00",
            end: "00:00",
            image: null,
            imageUrl: "",
            master: {},
            member: [],
            sheetSecret: true,
            memoSecret: true,
            roomSecret: "none",
            password: "",
            icon: "mdi-circle",
            complete: false,
            isNew: true,
            colorOpen: false,
            startOpen: false,
            endOpen: false,
            showpass: false,
            dragging: {},
            requireRules: [ v => !!v || `入力してください`],
            dateRules: [ v => /^\d{4}-\d{2}-\d{2}$/.test(v) || `日付の形式が不正です`, v => moment(v).isValid() || `日付の形式が不正です`],
            timeRules: [ v => /^\d\d?:\d{2}/.test(v) || `時刻の形式が不正です`],
            fileRules: [value => !value || value.size <= 5120000 || "画像サイズは5MBまでです"],
            colorRules: v => /^#[0123456789ABCDEFabcdef]{6}$/.test(v),
            icons: [ "mdi-circle", "mdi-triangle", "mdi-rhombus", "mdi-check-bold", "mdi-star", "mdi-white-balance-sunny", "mdi-moon-waning-crescent",
            "mdi-clover", "mdi-cards-heart", "mdi-cards-spade", "mdi-cards-club", "mdi-cards-diamond", 
            "mdi-dice-1", "mdi-dice-2", "mdi-dice-3", "mdi-dice-4", "mdi-dice-5", "mdi-dice-6",
            "mdi-seal", "mdi-music-note-eighth", "mdi-gamepad-variant", "mdi-sword-cross" ]
        }
    },
    computed: {
        url() {
            if (this.image) {
                return URL.createObjectURL(this.image);
            } else if (this.imageUrl != "") {
                return this.imageUrl;
            } else {
                return "404";
            }
        },
        minStep() {
            return m => m % 5 === 0;
        }
    },
    methods: {
        async checkData() {
            this.id = this.$route.params.eventId;
            const docRef = doc(this.$db, "group", this.id);
            const docSnap = await getDoc(docRef);
            if (!docSnap.data()) {
                this.$router.push("/groups");
            }
            const data = docSnap.data();
            if (data.master != this.user) {
                this.$router.push("/group/" + this.id );
            }
            this.name = data.name;
            this.color = data.color;
            this.imageUrl = data.image;
            this.roomSecret = data.roomSecret;
            this.master.id = data.master;
            this.complete = data.complete;
            for(var j=0; j < data.member.length; j++) {
                this.member.push({id: data.member[j]});
            }
            let snap = await getDoc(doc(this.$db, "group", this.id, "public", "content"));
            if(snap.exists()) {
                const docdata = snap.data();
                this.detail = docdata.detail;
                this.start = docdata.start;
                this.end = docdata.end;
                this.sheetSecret = docdata.sheetSecret;
                this.memoSecret = docdata.memoSecret;
                this.icon = docdata.icon;
                this.isNew = false;
            } else this.isNew = true;
            if (this.roomSecret == "password") {
                let snap = await getDoc(doc(this.$db, "group", this.id, "secret", "main"));
                if (snap.exists()) {
                    const data = snap.data();
                    this.password = data.password;
                }
            }
            const masterSnapshot = await getDoc(doc(this.$db, "user", this.master.id));
            if (masterSnapshot.exists()) {
                const data = masterSnapshot.data();
                this.$set(this.master, "name", data.name);
                this.$set(this.master, "icon", data.icon);
                this.$set(this.master, "color", data.color);
            }
            for (var i = 0; i < this.member.length; i++) {
                const snapshot = await getDoc(doc(this.$db, "user", this.member[i].id));
                const data = snapshot.data();
                this.$set(this.member[i], "name", data.name);
                this.$set(this.member[i], "icon", data.icon);
                this.$set(this.member[i], "color", data.color);
            }
        },
        cancel() {
            this.$router.push("/group/" + this.id);
        },
        async submit() {
            if (this.$refs.form.validate() && this.colorRules(this.color)) {
                if (this.complete == true) {
                    var yes = confirm("完走済み卓としてマークします。よろしいですか？")
                    if (!yes) return;
                }
                if (this.master.id != this.user) {
                    yes = confirm("GM権限を譲渡します。よろしいですか？")
                    if (!yes) return;
                }
                if (this.image != null) {
                    const ex = this.image.type.split("/")[1];
                    const storageRef = ref(this.$storage, "group_header/" + this.id + "." + ex);
                    await uploadBytes(storageRef, this.image);
                    this.imageUrl = await getDownloadURL(storageRef);
                }
                var memberArr = [];
                for (var mem in this.member) {
                    memberArr.push(this.member[mem].id);
                }
                await setDoc(doc(this.$db, "group", this.id), {
                    name: this.name,
                    color: this.color,
                    image: this.imageUrl,
                    roomSecret: this.roomSecret,
                    complete: this.complete,
                    master: this.user,
                    member: memberArr
                }, { merge: true });
                await setDoc(doc(this.$db, "group", this.id, "public", "content"), {
                    detail: this.detail,
                    start: this.start,
                    end: this.end,
                    icon: this.icon,
                    room: this.room,
                    memoSecret: this.memoSecret,
                    sheetSecret: this.sheetSecret
                }, { merge: true });
                if (this.roomSecret == "password") {
                    await setDoc(doc(this.$db, "group", this.id, "secret", "master"), {
                        password: this.password
                    });
                }
                this.$router.push("/group/" + this.id);
            }
        },
        async discard() {
            var yes = confirm("卓を削除します。この操作は取り消せませんがよろしいですか？");
            if (yes) {
                await deleteDoc(doc(this.$db, "group", this.id));
                this.$router.push("/groups");
            }
        },
        omitPlayer(index) {
            var yes = confirm("プレイヤーを削除します。よろしいですか？");
            if (!yes) return;
            this.member.splice(index, 1);
        }
    }
}
</script>script