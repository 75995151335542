<template>
    <v-main class="pa-0">
        <v-sheet width="100%">
        <v-img :aspect-ratio="3/1" width="100%" height="100%" :src="image">
            <template v-slot:placeholder><v-sheet height="100%" width="100%" :color="color"></v-sheet></template>
        </v-img>
        </v-sheet>
            <v-toolbar :color="color" :dark="autoDark(color)">
                <v-toolbar-title>{{name}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text :color="autoDark(color) ? 'white' : 'black'" to="/groups">一覧へ</v-btn>
                <v-btn text :color="autoDark(color) ? 'white' : 'black'" :to="'/editgroup/' + id" v-if="isMaster">編集</v-btn>
                <template v-slot:extension>
                    <v-tabs dark v-model="wrapTab" grow v-if="isMaster || isMember">
                        <v-tabs-slider :color="autoDark(color)? 'white' : 'black'"></v-tabs-slider>
                        <v-tab>概要</v-tab>
                        <v-tab>日程</v-tab>
                        <v-tab>チャット</v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
        <v-container v-if="isMaster || isMember">
            <v-tabs-items v-model="wrapTab">
                <v-tab-item>
            <v-sheet outlined v-if="detail != ''" class="pa-6 prewrap" v-html="sanitize(detail)"></v-sheet>
            <groupMembers :id="id" :master="master" :member="member" :color="color" :user="user" :room="room" :sheetSecret="sheetSecret">
            </groupMembers>
            <groupMemo :id="id" :color="color" :master="master" :member="member" :user="user" :memoSecret="memoSecret"></groupMemo>
            </v-tab-item>
            <v-tab-item>
            <v-row>
            <v-col cols="12" md="4">
                <v-sheet outlined>
                <div class="d-flex" height="20">
                    <v-btn icon class="ma-2" size="x-small"
                    @click="$refs.calendar.prev()">
                    <v-icon>mdi-chevron-left-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="setMonth">{{calendarSpan.split("-")[1] + "月"}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon class="ma-2" size="x-small"
                    @click="$refs.calendar.next()">
                    <v-icon>mdi-chevron-right-circle</v-icon>
                    </v-btn>
                </div>
                <v-calendar ref="calendar"
                type="month"
                v-model="calendarSpan"
                @click:date="setDate">
                <template v-slot:day="slotProps">
                    <v-container class="scheduleIcon pa-0">
                        <v-row align="center">
                            <v-col>
                                <v-icon v-if="isScheduled(slotProps.date)" :color="color" size="30">{{icon}}</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                </v-calendar>
                </v-sheet>
            </v-col>
            <v-col cols="12" md="8">
                <v-sheet class="pa-4" outlined>
                    <div class="text-subtitle-1">{{displayDay}}</div>
                    <v-row class="px-3">
                    <v-menu v-model="startOpen" :close-on-content-click="false" offset-y>
                        <template v-slot:activator="{on, attrs}">
                        <v-text-field label="開始" v-model="start" v-bind="attrs" v-on="on" readonly></v-text-field>
                        </template>
                        <v-time-picker v-if="startOpen" full-width v-model="start" format="24hr" scrollable :allowed-minutes="minStep"></v-time-picker>
                    </v-menu>
                〜
                <v-menu v-model="endOpen" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{on, attrs}">
                    <v-text-field label="終了" v-model="end" v-bind="attrs" v-on="on" readonly></v-text-field>
                    </template>
                    <v-time-picker v-if="endOpen" full-width v-model="end" format="24hr" scrollable :allowed-minutes="minStep"></v-time-picker>
                </v-menu>
            </v-row>
                    <v-simple-table>
                        <thead><tr>
                            <th v-if="schedulemonth"></th>
                            <th>{{master.name}}</th>
                            <th v-for="mem in member" :key="mem.id">{{mem.name}}</th>
                        </tr></thead>
                        <tbody v-if="!schedulemonth">
                            <tr :class="{'pink lighten-5': isBlank.every(item => item == '◯') && !$vuetify.theme.dark, 'primary lighten-5': isBlank.every(item => item == '★') && !$vuetify.theme.dark,
                                'pink darken-4': isBlank.every(item => item == '◯') && $vuetify.theme.dark, 'primary darken-4': isBlank.every(item => item == '★') && $vuetify.theme.dark}"
                                @click="showScheduleMenu($event, 0, isBlank.some(item => item == '★'))">
                                <td v-for="(item, index) in isBlank" :key="index" :class="{'pink--text': item == '◯', 'primary--text': item == '★'}">{{item || "-"}}</td></tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item, i) in isBlank" :key="i" :class="{'pink lighten-5': item.every(jtem => jtem == '◯') && !$vuetify.theme.dark, 'primary lighten-5': item.every(jtem => jtem == '★') && !$vuetify.theme.dark,
                                'pink darken-4': item.every(jtem => jtem == '◯') && $vuetify.theme.dark, 'primary darken-4': item.every(jtem => jtem == '★') && $vuetify.theme.dark}"
                                @click="showScheduleMenu($event, i+1, item.some(jtem => jtem == '★'))">
                                <th>{{i+1}}</th>
                                <td v-for="(jtem, j) in isBlank[i]" :key="j" :class="{'pink--text': jtem == '◯', 'primary--text': jtem == '★', 'text--lighten-2': $vuetify.theme.dark}">{{jtem || "-"}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-menu v-model="scheduleMenu" :position-x="scheduleMenuX" :position-y="scheduleMenuY" absolute offset-x>
                        <v-card>
                            <v-toolbar
                                :color="color"
                                :dark="autoDark(color)"
                                >
                            <v-toolbar-title>{{scheduleTime}}</v-toolbar-title></v-toolbar>
                            <v-card-text>
                                <v-row class="pa-3">
                            <v-spacer></v-spacer>
                            <v-btn @click="deleteSchedule" v-if="hasEvent">予定削除</v-btn>
                            <v-btn @click="appendSchedule" :color="color" :dark="autoDark(color)" v-else>予定追加</v-btn>
                            </v-row></v-card-text>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
            </v-row>
            </v-tab-item>
            <v-tab-item>
            <groupChat :id="id" :color="color" :master="master" :member="member" :user="user"></groupChat>
            </v-tab-item>
            </v-tabs-items>
        </v-container>
        <v-container v-if="!isMaster && !isMember">
            <v-form>
            <v-row align-content="center" style="height: 300px">
            <v-col align="center" height="100%">
                <div class="text-h5">部屋に参加する</div>
                <v-text-field v-if="roomSecret == 'password'" v-model="password" label="パスワード" required
             :type="showpass ? 'text' : 'password'" :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showpass = !showpass"></v-text-field>
                <v-btn :color="color" @click="join" :dark="autoDark(color)" class="ma-8" type="submit">参加</v-btn>
            </v-col>
            </v-row></v-form>
        </v-container>
        <v-overlay :value="loading" opacity="100" :color="$vuetify.theme.dark ? 'black' : 'white'">
            <v-progress-circular color="primary" indeterminate size="42"></v-progress-circular>
        </v-overlay>
    </v-main>
</template>
<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, onSnapshot, getDoc, getDocs, query, where, updateDoc, arrayUnion, addDoc, deleteDoc } from '@firebase/firestore';
import sanitizeHtml from "sanitize-html";
const marked = require("marked");
import moment from "moment";

import groupMembers from './group_members.vue';
import groupMemo from './group_memo.vue';
import groupChat from './group_chat.vue';

export default {
    components: {
        groupMembers,
        groupMemo,
        groupChat
    },

    mounted() {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.user = user.uid;
                this.getData();
            } else {
                this.$router.push("/login");
            }
        });
    },
    data() {
        return {
            user: "",
            id: "",
            name: "",
            master: {},
            member: [],
            color: this.$vuetify.theme.themes.light.primary,
            roomSecret: "none",
            detail: "",
            image: "404",
            room: "",
            start: "",
            end: "",
            icon: "mdi-circle",
            sheetSecret: true,
            memoSecret: true,
            chat: {},
            memo: "",
            schedule: {},
            isBlank: [],
            calendarSpan: moment().format("YYYY-MM-DD"),
            scheduleday: "",
            schedulemonth: false,
            startOpen: false,
            endOpen: false,
            password: "",
            showpass: false,
            sheetListen: null,
            memoListen: null,
            loading: true,
            wrapTab: 0,
            scheduleMenu: false,
            scheduleMenuX: 0,
            scheduleMenuY: 0,
            scheduleMenuDay: 0,
            hasEvent: false
        }
    },
    computed: {
        isMaster() {
            return this.user == this.master.id;
        },
        isMember() {
            return this.member.some((mem) => mem.id == this.user);
        },
        isScheduled() {
            return function(date) {
                const dateStart = new Date(date + "T00:00:00");
                const dateEnd = new Date(date + "T23:59:59");
                var result = false;
                Object.keys(this.schedule).forEach((key) => {
                    const event = this.schedule[key];
                    if (event.start.toDate() >= dateStart && event.start.toDate() <= dateEnd) result = true;
                });
                return result;
            }
        },
        displayDay() {
            if (!this.scheduleday) return "---";
            else if (this.schedulemonth) {
                const dateArray = this.scheduleday.split("-");
                return `${dateArray[0]}年${dateArray[1]}月`;
            } else {
                const dateArray = this.scheduleday.split("-");
                return `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`
            }
        },
        scheduleTime() {
            if (!this.scheduleday) return "";
            const dateArray = this.scheduleday.split("-");
            if (this.schedulemonth) {
                return `${dateArray[0]}年${dateArray[1]}月${this.scheduleMenuDay}日  ${this.start}〜${this.end}`
            } else {
                return `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日  ${this.start}〜${this.end}`
            }
        },
        minStep() {
            return m => m % 5 === 0;
        }
    },
    methods: {
        async getData() {
            this.id = this.$route.params.eventId;
            var contentListen = null;
            var scheduleListen = null;
            var secretListen = null;
            onSnapshot(doc(this.$db, "group", this.id), (docu) => {
                if (!docu.exists()) {
                    this.$router.push("/groups");
                }
                const data = docu.data();
                this.$set(this.master, "id", data.master);
                if (data.member.length > 0) {
                    this.member.splice(0, this.member.length);
                    data.member.forEach(mem => {
                        this.member.push({id: mem});
                    });
                }
                this.name = data.name;
                this.color = data.color;
                this.image = data.image;
                this.roomSecret = data.roomSecret;
                if (this.isMaster || this.isMember) {
                    this.userListenFunc();
                }
                if (contentListen == null && this.isMaster || this.isMember) {
                    contentListen = onSnapshot(doc(this.$db, "group", this.id, "public", "content"), (doc) => {
                        const data = doc.data();
                        this.detail = data.detail;
                        this.room = data.room;
                        this.start = data.start;
                        this.end = data.end;
                        this.icon = data.icon;
                        this.$set(this.master, "desc", data.masterDesc);
                        this.$set(this.master, "sheet", data.masterSheet);
                        this.sheetSecret = data.sheetSecret;
                        this.memoSecret = data.memoSecret;
                        this.$set(this.master, "memo", data.memo);
                    });
                    
                }
                if (scheduleListen == null && this.isMaster || this.isMember) {
                    const col = collection(this.$db, "schedule");
                    scheduleListen = onSnapshot(query(col, where("user", "==", this.master.id), where("group", "==", this.id)), (snapshot) => {
                        snapshot.docChanges().forEach((change) => {
                            if (change.type == "added") {
                                this.$set(this.schedule, change.doc.id, change.doc.data());
                            } else if(change.type == "modified") {
                                this.$set(this.schedule, change.doc.id, change.doc.data());
                            } else if(change.type == "removed") {
                                this.$delete(this.schedule, change.doc.id);
                            }
                        })
                    });
                }
                if (secretListen == null && this.isMaster || this.isMember) {
                    secretListen = [];
                    for(var j = 0; j < this.member.length; j++) {
                        secretListen.push(this.secretListenFunc(this.member[j], j))
                    }
                }
                this.loading = false;
            });
        },
        async setBlank() {
            if (!this.scheduleday) {
                this.isBlank.splice(0);
                return;
            }
            if (!this.schedulemonth) {
                this.isBlank.splice(0);
                let start = moment(this.scheduleday + " " + this.start).toDate();
                let start_start = moment(this.scheduleday + " " + this.start).add(-1, "d").toDate();
                let end = moment(this.scheduleday + " " + this.end).toDate();
                if (start >= end) end = moment(this.scheduleday + " " + this.end).add(1, "d").toDate()
                await getDocs(query(collection(this.$db, "schedule"), where("user", "==", this.master.id), where("start", "<=", end), where("start", ">=", start_start))).then(snapshot => {
                    if (snapshot.docs.some(event => event.data().end.toDate() >= start && event.data().group == this.id)) this.$set(this.isBlank, 0, "★");
                    else if (snapshot.docs.some(event => event.data().end.toDate() >= start)) this.$set(this.isBlank, 0, "×");
                    else this.$set(this.isBlank, 0, "◯");
                });
                for (var i = 0; i < this.member.length; i++) {
                    await getDocs(query(collection(this.$db, "schedule"), where("user", "==", this.member[i].id), where("start", "<=", end), where("start", ">=", start_start))).then(snapshot => {
                        if (snapshot.docs.some(event => event.data().end.toDate() >= start && event.data().group == this.id)) this.$set(this.isBlank, i+1, "★");
                        else if (snapshot.docs.some(event => event.data().end.toDate() >= start)) this.$set(this.isBlank, i+1, "×");
                        else this.$set(this.isBlank, i+1, "◯");
                    });
                }
            } else {
                this.isBlank.splice(0);
                const now = moment(this.scheduleday);
                for (var date = 0; date < now.daysInMonth(); date++) {
                    this.$set(this.isBlank, date, []);
                    var start = moment(this.scheduleday + " " + this.start).date(date + 1).toDate();
                    var start_start = moment(this.scheduleday + " " + this.start).date(date).toDate();
                    var end = moment(this.scheduleday + " " + this.end).date(date + 1).toDate();
                    if (start >= end) end = moment(this.scheduleday + " " + this.end).date(date + 2).toDate()
                    await getDocs(query(collection(this.$db, "schedule"), where("user", "==", this.master.id), where("start", "<=", end), where("start", ">=", start_start))).then(snapshot => {
                        if (snapshot.docs.some(event => event.data().end.toDate() >= start && event.data().group == this.id)) this.$set(this.isBlank[date], 0, "★");
                        else if (snapshot.docs.some(event => event.data().end.toDate() >= start)) this.$set(this.isBlank[date], 0, "×");
                        else this.$set(this.isBlank[date], 0, "◯");
                    });
                    for (var j = 0; j < this.member.length; j++) {
                        await getDocs(query(collection(this.$db, "schedule"), where("user", "==", this.member[j].id), where("start", "<=", end), where("start", ">=", start_start))).then(snapshot => {
                            if (snapshot.docs.some(event => event.data().end.toDate() >= start && event.data().group == this.id)) this.$set(this.isBlank[date], j+1, "★");
                            else if (snapshot.docs.some(event => event.data().end.toDate() >= start)) this.$set(this.isBlank[date], j+1, "×");
                            else this.$set(this.isBlank[date], j+1, "◯");
                        });
                    }
                }
            }
        },
        async join(event) {
            event.preventDefault();
            if (this.roomSecret == "password") {
                const snapshot = await getDocs(query(collection(this.$db, "group", this.id, "secret"), where("password", "==", this.password)));
                if (snapshot.docs.length == 0) {
                    alert("パスワードが一致しません");
                    return;
                }
            }
            await updateDoc(doc(this.$db, "group", this.id), {
                member: arrayUnion(this.user)
            });
        },
        async appendSchedule() {
            let start = moment(this.scheduleTime.split(" ")[0] + this.start, "YYYY年MM月DD日HH:mm");
            let end = moment(this.scheduleTime.split(" ")[0] + this.end, "YYYY年MM月DD日HH:mm");
            if (this.end == "00:00") end.hour(23).minute(59).second(59);
            if (start.isSameOrAfter(end)) end.add(1, "d");
            await addDoc(collection(this.$db, "schedule"), {
                group: this.id,
                name: this.name,
                start: start.toDate(),
                end: end.toDate(),
                detail: "",
                color: this.color,
                category: "TRPG予定",
                user: this.master.id
            });
            for (var mem in this.member) {
                await addDoc(collection(this.$db, "schedule"), {
                group: this.id,
                name: this.name,
                start: start.toDate(),
                end: end.toDate(),
                detail: "",
                color: this.color,
                category: "TRPG予定",
                user: this.member[mem].id
            });
            }
            this.setBlank();
        },
        async deleteSchedule() {
            const startday = moment(this.scheduleTime.split(" ")[0], "YYYY年MM月DD日").toDate();
            const endday = moment(this.scheduleTime.split(" ")[0], "YYYY年MM月DD日").add(1, "d").toDate();
            const targets = await getDocs(query(collection(this.$db, "schedule"), where("group", "==", this.id), where("start", ">=", startday), where("start", "<=", endday)));
            targets.forEach(async doc => {
                await deleteDoc(doc.ref);
            });
            this.setBlank();
        },
        secretListenFunc(mem, i) {
            return onSnapshot(doc(this.$db, "group", this.id, "secret", mem.id), (snapshot) => {
                if (!snapshot.exists()) return;
                const data = snapshot.data();
                this.$set(this.member[i], "sheet", data.sheet);
                this.$set(this.member[i], "memo", data.memo);
                this.$set(this.member[i], "desc", data.desc);
            });
        },
        async userListenFunc() {
            const masterSnapshot = await getDoc(doc(this.$db, "user", this.master.id));
            if (masterSnapshot.exists()) {
                const data = masterSnapshot.data();
                this.$set(this.master, "name", data.name);
                this.$set(this.master, "icon", data.icon);
                this.$set(this.master, "color", data.color);
            }
            for (var i = 0; i < this.member.length; i++) {
                const snapshot = await getDoc(doc(this.$db, "user", this.member[i].id));
                const data = snapshot.data();
                this.$set(this.member[i], "name", data.name);
                this.$set(this.member[i], "icon", data.icon);
                this.$set(this.member[i], "color", data.color);
            }
        },
        showScheduleMenu(event, i, hasEvent) {
            event.preventDefault();
            this.scheduleMenu = false;
            this.scheduleMenuDay = i;
            this.hasEvent = hasEvent;
            this.scheduleMenuX = event.clientX;
            this.scheduleMenuY = event.clientY;
            this.$nextTick(() => this.scheduleMenu = true);
        },
        
        setDate(target) {
            this.schedulemonth = false;
            this.scheduleday = target.date;
        },
        setMonth() {
            this.schedulemonth = true;
            this.scheduleday = this.calendarSpan
        },
        autoDark(color) {
            if (!color) return "";
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
        sanitize(string) {
            if (!string) return "";
            return sanitizeHtml(marked.parse(string));
        }
    },
    watch: {
        scheduleday: function(now, old) {
            if (!this.schedulemonth || now.split("-")[1] != old.split("-")[1] || now.split("-")[0] != old.split("-")[0]) {
                this.setBlank()
            }
        },
        schedulemonth: function() {
            this.setBlank()
        },
        start: function() {
            this.setBlank()
        },
        end: function() {
            this.setBlank()
        },
        schedule: function() {
            this.setBlank()
        }
    }
}
</script>
<style scoped>
.prewrap {
    white-space: pre-wrap;
}
.scheduleIcon {
    opacity: 0.75;
    text-align: center;
    height: 46px;
    z-index: -100;
}
.v-tab--active {
    color: white;
    font-weight: bold;
}
</style>