<template>
    <v-sheet outlined class="py-4">
        <v-tabs v-model="tab" :color="color" v-if="isMaster || !memoSecret">
            <v-tab key="master">全体メモ</v-tab>
            <v-tab v-for="mem in member" :key="mem.id">{{mem.name}}</v-tab>
        </v-tabs>
        <v-tabs v-model="tab" :color="color" v-else>
            <v-tab key="master">全体メモ</v-tab>
            <v-tab :key="user">{{me.name}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" v-if="isMaster || !memoSecret">
            <v-tab-item key="master">
                <v-container>
                    <v-row><v-sheet class="pa-4 prewrap" min-height="150" v-html="sanitize(master.memo)"></v-sheet></v-row>
                    <v-row><v-spacer></v-spacer><v-col  align="end" align-self="end">
                        <v-btn :color="color" :dark="autoDark(color)" @click.stop="showDialog(0)">編集</v-btn>
                    </v-col></v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item v-for="mem, index in member" :key="mem.id">
                <v-container>
                    <v-row><v-sheet class="pa-4 prewrap" min-height="150" v-html="sanitize(mem.memo)"></v-sheet></v-row>
                    <v-row><v-col align="end" align-self="end">
                        <v-btn v-if="isMaster || mem.id == me.id" :color="color" :dark="autoDark(color)" @click.stop="showDialog(index+1)">編集</v-btn>
                    </v-col></v-row>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab" v-else>
            <v-tab-item key="master">
                <v-container>
                    <v-row><v-sheet class="pa-4 prewrap" min-height="150" v-html="sanitize(master.memo)"></v-sheet></v-row>
                    <v-row><v-spacer></v-spacer><v-col  align="end" align-self="end">
                        <v-btn :color="color" :dark="autoDark(color)" @click.stop="showDialog(0)">編集</v-btn>
                    </v-col></v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item :key="user">
                <v-container>
                    <v-row><v-sheet class="pa-4 prewrap" min-height="150" v-html="sanitize(me.memo)"></v-sheet></v-row>
                    <v-row><v-col align="end" align-self="end">
                        <v-btn :color="color" :dark="autoDark(color)" @click.stop="showDialog(me.index+1)">編集</v-btn>
                    </v-col></v-row>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="dialog">
            <v-card class="pt-6"><v-card-text><v-form>
                <v-textarea v-model="dialogText" label="メモ（HTMLタグ使用可）"></v-textarea>
                <v-row><v-col align="end" align-self="end">
                        <v-btn type="button" :color="color" :dark="autoDark(color)" @click.stop="submitMemo">決定</v-btn>
                    </v-col></v-row>
            </v-form></v-card-text></v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
import sanitizeHtml from 'sanitize-html'
const marked = require("marked");
import { doc, setDoc, updateDoc } from '@firebase/firestore';

export default {
    props: [
        "id",
        "color",
        "master",
        "member",
        "user",
        "memoSecret"
    ],
    data() {
        return {
            tab: "",
            dialog: false,
            dialogText: "",
            dialogTarget: 0
        }
    },
    computed: {
        isMaster() {
            return this.user == this.master.id;
        },
        me() {
            var me = this.member.find(mem => mem.id == this.user);
            if (!me) return this.master;
            me.index = this.member.findIndex(mem => mem.id == this.user);
            return me;
        }
    },
    methods: {
        sanitize(string) {
            if (!string) return "";
            return sanitizeHtml(marked.parse(string));
        },
        autoDark(color) {
            if (!color) return "";
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
        showDialog(target) {
            this.dialogTarget = target;
            if (target == 0) {
                this.dialogText = this.master.memo;
            } else {
                this.dialogText = this.member[target-1].memo;
            }
            this.dialog = true;
        },
        async submitMemo() {
            if (this.dialogTarget == 0) {
                await updateDoc(doc(this.$db, "group", this.id, "public", "content"), {
                    memo: this.dialogText
                });
            } else {
                await setDoc(doc(this.$db, "group", this.id, "secret", this.member[this.dialogTarget-1].id), {
                    memo: this.dialogText
                }, {merge: true});
            }
            this.dialog = false;
        }
    }
}
</script>
<style scoped>
.prewrap {
    white-space: pre-wrap;
}
</style>