import Vue from 'vue';
import Router from 'vue-router';
import top from '../components/top.vue';
import login from '../components/login.vue';
import calender from '../components/calender.vue';
import groups from '../components/groups.vue';
import group from '../components/group.vue';
import editgroup from '../components/editgroup.vue';
import setting from '../components/setting.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'top',
            component: top
        },
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/calender',
            name: 'calender',
            component: calender
        },
        {
            path: '/groups',
            name: 'groups',
            component: groups
        },
        {
            path: '/group/:eventId',
            name: 'group',
            component: group
        },
        {
            path: '/editgroup/:eventId',
            name: 'editgroup',
            component: editgroup
        },
        {
            path: '/setting',
            name: 'setting',
            component: setting
        }
    ]
})