import { render, staticRenderFns } from "./group_memo.vue?vue&type=template&id=87d271d6&scoped=true&"
import script from "./group_memo.vue?vue&type=script&lang=js&"
export * from "./group_memo.vue?vue&type=script&lang=js&"
import style0 from "./group_memo.vue?vue&type=style&index=0&id=87d271d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87d271d6",
  null
  
)

export default component.exports