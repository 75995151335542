<template>
    <v-app-bar app clipped-left :class="autoDark($vuetify.theme.themes.light.primary)? 'white--text': 'black--text'" color="primary">
        <v-app-bar-nav-icon @click="$emit('nav')">
            <v-img src="../assets/logo.png" height="48" width="48"></v-img>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="title">LivLa9
            <span class="caption mx-3">vβ.0.2</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text :color="autoDark($vuetify.theme.themes.light.primary) ? 'white' : 'black'" @click="logout" v-if="user">
            ログアウト
        </v-btn>
    </v-app-bar>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

export default {
    mounted() {
        const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        if(user) {
            this.user = user.uid;
        } else {
            this.user = undefined;
        }
        });
    },

    data() {
        return {
            user: this.$user,
        }
    },
    methods: {
        logout() {
            const auth = getAuth();
            signOut(auth).then(() => {
            }).catch((error) => {
                alert(error);
            });
        },
        autoDark(color) {
            if (!color) return false;
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
    }
}
</script>