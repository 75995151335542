<template>
    <v-sheet outlined class="pa-6">
        <a class="text-subtitle-1" :href="room" v-if="room" target="_blank" rel="noopener noreferrer" :style="`color: ${color};`">部屋リンク</a>
        <div class="text-subtitle-1">GM</div>
        <v-container>
        <v-row align="center">
            <v-avatar size="28" :color="master.color">
                <v-img :src="master.icon"></v-img>
            </v-avatar>
            <span class="mx-5">{{master.name}}</span>
            <a class="mx-2" v-if="master.sheet && (!sheetSecret || isMaster)" :href="master.sheet" target="_blank" rel="noopener noreferrer" :style="`color: ${color};`">
                キャラシ</a>
            <span class="text-body-2 grey--text" :class="{'text--darken-3': !$vuetify.theme.dark}">{{master.desc}}</span>
            <v-spacer></v-spacer>
            <v-btn icon :color="color" size="x-small" v-if="isMaster" @click.stop="showSheetDialog(0)"><v-icon>mdi-file-account</v-icon></v-btn>
            <v-btn icon :color="color" size="x-small" v-if="isMaster" @click.stop="showDescDialog(0)"><v-icon>mdi-pencil</v-icon></v-btn>
        </v-row>
        </v-container>
        <div class="text-subtitle-1">PL</div>
        <v-container>
        <v-row align="center" v-for="(mem, index) in member" :key="mem.id">
            <v-avatar size="28" :color="mem.color">
                <v-img :src="mem.icon"></v-img>
            </v-avatar>
            <span class="mx-5">{{mem.name}}</span>
            <a class="mx-3" v-if="mem.sheet && (!sheetSecret || isMaster || mem.id == user)" :href="mem.sheet" target="_blank" rel="noopener noreferrer">
                キャラシ</a>
            <span class="text-body-2 grey--text" :class="{'text--darken-3': !$vuetify.theme.dark}" v-if="mem.desc">{{mem.desc}}</span>
            <v-spacer></v-spacer>
            <v-btn icon :color="color" size="x-small" v-if="isMaster || mem.id == user" @click.stop="showSheetDialog(index+1)"><v-icon>mdi-file-account</v-icon></v-btn>
            <v-btn icon :color="color" size="x-small" v-if="isMaster || mem.id == user" @click.stop="showDescDialog(index+1)"><v-icon>mdi-pencil</v-icon></v-btn>
        </v-row>
        </v-container>
        <v-dialog v-model="descDialog" max-width="600px">
            <v-card class="pt-6"><v-card-text><v-form>
            <v-row>
                <v-text-field v-model="dialogText" label="一言メモ"></v-text-field>
                <v-btn icon type="submit" @click="submitDesc" size="x-small" class="mt-4" :color="color"><v-icon>mdi-send</v-icon></v-btn>
            </v-row>
            </v-form></v-card-text></v-card>
        </v-dialog>
        <v-dialog v-model="sheetDialog" max-width="600px">
            <v-card class="pt-6"><v-card-text><v-form>
            <v-row>
                <v-text-field v-model="dialogText" label="キャラシURL"></v-text-field>
                <v-btn icon type="submit" @click="submitSheet" size="x-small" class="mt-4"><v-icon>mdi-send</v-icon></v-btn>
            </v-row>
            </v-form></v-card-text></v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
import { doc, updateDoc, setDoc } from '@firebase/firestore'

export default {
    props: [
        "id",
        "master",
        "member",
        "color",
        "user",
        "room",
        "sheetSecret",
    ],
    data() {
        return {
            dialogText: "",
            dialogTarget: 0,
            descDialog: false,
            sheetDialog: false,
        }
    },
    computed: {
        isMaster() {
            return this.user == this.master.id;
        },
        isMember() {
            return this.member.some((mem) => mem.id == this.user);
        },
    },
    methods: {
        showDescDialog(target) {
            this.dialogTarget = target;
            this.descDialog = true;
            if (target == 0) {
                this.dialogText = this.master.desc;
            } else {
                this.dialogText = this.member[target-1].desc;
            }
        },
        async submitDesc(e) {
            e.preventDefault();
            if (this.dialogTarget == 0) {
                await updateDoc(doc(this.$db, "group", this.id, "public", "content"), {
                    masterDesc: this.dialogText
                });
            } else {
                await setDoc(doc(this.$db, "group", this.id, "secret", this.member[this.dialogTarget-1].id), {
                    desc: this.dialogText
                }, {merge: true});
            }
            this.descDialog = false;
        },
        showSheetDialog(target) {
            this.dialogTarget = target;
            this.sheetDialog = true;
            if (target == 0) {
                this.dialogText = this.master.sheet;
            } else {
                this.dialogText = this.member[target-1].sheet;
            }
        },
        async submitSheet(e) {
            e.preventDefault();
            if (this.dialogTarget == 0) {
                await updateDoc(doc(this.$db, "group", this.id, "public", "content"), {
                    masterSheet: this.dialogText
                });
            } else {
                await setDoc(doc(this.$db, "group", this.id, "secret", this.member[this.dialogTarget-1].id), {
                    sheet: this.dialogText
                }, {merge: true});
            }
            this.sheetDialog = false;
        }
    }
}
</script>