<template>
    <v-main>
        <div class="text-h5 ma-3">卓一覧</div>
        <v-container>
            <v-row>
                <v-col v-for="group in currentGroups" :key="group.key" lg="3" md="4" sm="6" cols="12">
                    <v-hover v-slot="{ hover }">
                    <v-card :to="'/group/' + group.key" :elevation="hover ? 6 : 2">
                        <v-img height="120" width="100%" :src="group.image">
                            <template v-slot:placeholder><v-sheet height="100%" width="100%" :color="group.color"></v-sheet></template>
                        </v-img>
                        <v-card-title>{{ group.name }}</v-card-title>
                    </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <v-btn class="my-5" v-if="!noMoreCurrent && currentGroups.length % maxTile == 0" block color="primary" @click="getMoreGroups">もっと見る</v-btn>
        </v-container>
        <v-sheet class="ma-3">
        <v-expansion-panels focusable>
            <v-expansion-panel>
                <v-expansion-panel-header @click="getOldGroups">完走済み卓</v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-container>
                    <v-row>
                        <v-col v-for="group in oldGroups" :key="group.key" lg="3" md="4" sm="6" cols="12">
                            <v-hover v-slot="{ hover }">
                            <v-card :to="'/group/' + group.key" :elevation="hover ? 6 : 2">
                                <v-img height="120" width="100%" :src="group.image">
                                    <template v-slot:placeholder><v-sheet height="100%" width="100%" :color="group.color"></v-sheet></template>
                                </v-img>
                                <v-card-title>{{ group.name }}</v-card-title>
                            </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-btn class="my-5" v-if="!noMoreOld && oldGroups.length % maxTile == 0" block color="primary" @click="getMoreOldGroups">もっと見る</v-btn>
                </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-sheet>
        <v-btn fab color="primary" @click="createGroup" x-large fixed bottom right><v-icon>mdi-plus</v-icon></v-btn>
    </v-main>
</template>
<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, addDoc, query, where, orderBy, limit, startAfter } from "firebase/firestore";

export default {
    mounted() {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.user = user.uid;
                this.getGroups();
            } else {
                this.$router.push("/login");
            }
        });
    },
    methods: {
        async getGroups() {
            if (!this.user) return;
            this.currentGroups = [];
            const groupsRef = collection(this.$db, "group");
            var q = query(groupsRef, where("master", "==", this.user), where("complete", "==", false), orderBy("created", "desc"), limit(this.maxTile));
            const mySnapshot = await getDocs(q);
            mySnapshot.forEach(doc => {
                var group = doc.data();
                group.key = doc.id;
                this.currentGroups.push(group);
            });
            if (this.currentGroups.length >= this.maxTile) {
                this.currentGroups.splice(this.maxTile);
                this.refCursor.currentEnd = mySnapshot.docs[this.maxTile - 1];
                this.refCursor.currentMaster = true;
            } else {
                const filled = this.currentGroups.length;
                q = query(groupsRef, where("member", "array-contains", this.user), where("complete", "==", false), orderBy("created", "desc"), limit(this.maxTile - filled));
                const ourSnapshot = await getDocs(q);
                if (ourSnapshot.size == 0) {
                    this.noMoreCurrent = true;
                    return;
                }
                ourSnapshot.forEach(doc => {
                    var group = doc.data();
                    group.key = doc.id;
                    this.currentGroups.push(group);
                });
                this.refCursor.currentEnd = ourSnapshot.docs[ourSnapshot.size - 1];
                this.refCursor.currentMaster = false;
            }
            
        },

        async getOldGroups() {
            if (!this.user) return;
            this.oldGroups = [];
            const groupsRef = collection(this.$db, "group");
            var q = query(groupsRef, where("master", "==", this.user), where("complete", "==", true), orderBy("created", "desc"), limit(this.maxTile));
            const myOldSnapshot = await getDocs(q);
            myOldSnapshot.forEach(doc => {
                var group = doc.data();
                group.key = doc.id;
                this.oldGroups.push(group);
            });
            if (this.oldGroups.length >= this.maxTile) {
                this.oldGroups.splice(this.maxTile);
                this.refCursor.oldEnd = myOldSnapshot.docs[this.maxTile - 1];
                this.refCursor.oldMaster = true;
            } else {
                const filled = this.oldGroups.length;
                q = query(groupsRef, where("member", "array-contains", this.user), where("complete", "==", true), orderBy("created", "desc"), limit(this.maxTile - filled));
                const ourOldSnapshot = await getDocs(q);
                if (ourOldSnapshot.size == 0) {
                    this.noMoreOld = true;
                    return;
                }
                ourOldSnapshot.forEach(doc => {
                    var group = doc.data();
                    group.key = doc.id;
                    this.oldGroups.push(group);
                });
                this.refCursor.oldEnd = ourOldSnapshot.docs[ourOldSnapshot.size - 1];
                this.refCursor.oldMaster = false;
            }
        },

        async getMoreGroups() {
            if(!this.user) return;
            const groupsRef = collection(this.$db, "group");
            var count = this.maxTile;
            if (this.refCursor.currentMaster) {
                const myquery = query(groupsRef, where("master", "==", this.user), where("complete", "==", false), orderBy("created", "desc"), startAfter(this.refCursor.currentEnd), limit(this.maxTile));
                const mySnapshot = await getDocs(myquery);
                mySnapshot.forEach(doc => {
                    var group = doc.data();
                    group.key = doc.id;
                    this.currentGroups.push(group);
                    count--;
                });
                if(count == 0) {
                    this.refCursor.currentMaster = true;
                    this.refCursor.currentEnd = mySnapshot.docs[this.maxTile - 1];
                    return;
                } else {
                    this.refCursor.currentEnd = new Date();
                }
            }
            const ourquery = query(groupsRef, where("member", "array-contains", this.user), where("complete", "==", false), orderBy("created", "desc"), startAfter(this.refCursor.currentEnd), limit(count));
            const ourSnapshot = await getDocs(ourquery);
            if (ourSnapshot.docs.length == 0) {
                this.noMoreCurrent = true;
                return;
            }
            ourSnapshot.forEach(doc => {
                var group = doc.data();
                group.key = doc.id;
                this.currentGroups.push(group);
            });
            this.refCursor.currentEnd = ourSnapshot.docs[ourSnapshot.length - 1];
            this.refCursor.currentMaster = false;
        },

        async getMoreOldGroups() {
            if(!this.user) return;
            const groupsRef = collection(this.$db, "group");
            var count = this.maxTile;
            if (this.refCursor.oldMaster) {
                const myOldQuery = query(groupsRef, where("master", "==", this.user), where("complete", "==", true), orderBy("created", "desc"), startAfter(this.refCursor.currentEnd), limit(this.maxTile));
                const myOldSnapshot = await getDocs(myOldQuery);
                myOldSnapshot.forEach(doc => {
                    var group = doc.data();
                    group.key = doc.id;
                    this.oldGroups.push(group);
                    count--;
                });
                if(count == 0) {
                    this.refCursor.oldMaster = true;
                    this.refCursor.oldEnd = myOldSnapshot.docs[this.maxTile - 1];
                    return;
                } else {
                    this.refCursor.oldEnd = new Date();
                }
            }
            const ourOldQuery = query(groupsRef, where("member", "array-contains", this.user), where("complete", "==", true), orderBy("created", "desc"), startAfter(this.refCursor.currentEnd), limit(count));
            const ourOldSnapshot = await getDocs(ourOldQuery);
            if (ourOldSnapshot.docs.length == 0) {
                this.noMoreOld = true;
                return;
            }
            ourOldSnapshot.forEach(doc => {
                var group = doc.data();
                group.key = doc.id;
                this.oldGroups.push(group);
            });
            this.refCursor.oldEnd = ourOldSnapshot.docs[ourOldSnapshot.length - 1];
            this.refCursor.currentMaster = false;
        },

        async createGroup() {
            if(!this.user) return;
            var docu = await addDoc(collection(this.$db, "group"), {
                        name: "あたらしい卓",
                        created: new Date(),
                        complete: false,
                        color: this.$vuetify.theme.themes.light.primary,
                        image: "404",
                        master: this.user,
                        member: [],
                        roomSecret: "none"
                    });
            this.$router.push("/editgroup/" + docu.id);
        }
    },
    data() {
        return {
            user: "",
            currentGroups:[],
            oldGroups:[],
            maxTile: 24,
            refCursor: {
                currentMaster: true,
                currentEnd: {},
                oldMaster: true,
                oldEnd: {}
            },
            noMoreCurrent: false,
            noMoreOld: false
        }
    }
}
</script>