<template>
    <v-sheet height="600" class="pa-4 overflow-y-auto scrollarea d-flex flex-column-reverse">
        <div v-for="state, key in chat" :key="key">
            <div v-if="isMine(state)" class="d-flex flex-column align-end">
            <v-card class="primary" :class="allDark ? 'darken-3' : 'lighten-5'" shaped max-width="70%">
                <v-card-text class="prewrap pb-0" :class="allDark ? 'white--text' : 'black--text'" v-html="htmlize(state.content)"></v-card-text>
                <v-img class="ma-2 mb-8" v-if="state.image" contain max-width="95%" :src="state.image">
                        <template v-slot:placeholder><v-sheet height="300" width="95%" :color="color"></v-sheet></template>
                    </v-img>
                <v-card-actions class="mt-n8">
                    <v-btn v-if="state.content" icon x-small color="grey" @click.stop="showEdit(key)"><v-icon>{{state.modified ? "mdi-circle-edit-outline" : "mdi-pencil"}}</v-icon></v-btn><v-spacer></v-spacer>
                    <span class="caption grey--text text--darken-2 px-2">{{whois(state.from).name}}</span>
                <v-avatar size="24" :color="whois(state.from).color">
                    <v-img :src="whois(state.from).icon"></v-img>
                </v-avatar>
                </v-card-actions>
            </v-card>
            <div class="grey--text text--darken-2 my-1 caption align-self-end">{{parseDate(state.created)}}</div>
            </div>
            <div v-else class="d-flex flex-column align-start">
            <v-card class="grey" :class="allDark ? 'darken-3' : 'lighten-5'" shaped max-width="70%">
                <v-card-text class="prewrap pb-0" :class="allDark ? 'white--text' : 'black--text'" v-html="htmlize(state.content)"></v-card-text>
                <v-img class="ma-2 mb-8 chat-image" v-if="state.image" contain max-width="95%" :src="state.image">
                        <template v-slot:placeholder><v-sheet height="300" width="95%" :color="color"></v-sheet></template>
                    </v-img>
                <v-card-actions class="mt-n8">
                    <v-avatar size="24" :color="whois(state.from).color">
                    <v-img :src="whois(state.from).icon"></v-img>
                </v-avatar>
                <span class="caption grey--text text--darken-2 px-2">{{whois(state.from).name}}</span><v-spacer></v-spacer>
                <v-icon color="grey" v-if="state.modified">mdi-circle-edit-outline</v-icon>
                </v-card-actions>
            </v-card>
            <div class="grey--text text--darken-2 my-1 caption align-self-start">{{parseDate(state.created)}}</div>
            </div>
        </div>
        <v-dialog v-model="dialog">
            <v-card class="pt-6"><v-card-text><v-form>
                <v-textarea v-model="dialogText"></v-textarea>
                <v-row><v-col align="end" align-self="end">
                        <v-btn type="button" :color="color" :dark="autoDark(color)" @click.stop="submitEdit">編集</v-btn>
                    </v-col></v-row>
            </v-form></v-card-text></v-card>
        </v-dialog>
    </v-sheet>
</template>
<script>
import sanitizeHtml from 'sanitize-html'
const marked = require("marked");
import moment from 'moment';
import {onSnapshot, collection, query, where, orderBy, updateDoc, doc, arrayUnion} from '@firebase/firestore'

export default {
    props: [
        "id",
        "user",
        "color",
        "tab",
        "master",
        "member"
    ],
    data() {
        return {
            chat: [],
            dialog: false,
            dialogText: "",
            editTarget: "",
            allDark: this.$vuetify.theme.dark
        }
    },
    mounted() {
        onSnapshot(query(collection(this.$db, "group", this.id, "chat"), where("tab", "==", this.tab), orderBy("created")), (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                var index = 0;
                if (change.type == "added") {
                    this.chat.unshift(change.doc.data());
                    this.$set(this.chat[index], "key", change.doc.id);
                    var read = false;
                    if (change.doc.data().read) read = change.doc.data().read.includes(this.user);
                    this.$emit("receive", {
                        content: change.doc.data().content,
                        tab: this.tab,
                        from: change.doc.data().from,
                        read: read,
                        id: change.doc.id
                    });
                    const scrollAreas = document.querySelectorAll("div.scrollarea");
                    this.$nextTick(function() {scrollAreas.forEach(area => area.scrollTop = area.scrollHeight)});
                } else if(change.type == "modified") {
                    index = this.chat.findIndex(el => el.key == change.doc.id);
                    this.$set(this.chat, index, change.doc.data());
                    this.$set(this.chat[index], "key", change.doc.id);
                } else if(change.type == "removed") {
                    index = this.chat.findIndex(el => el.key == change.doc.id);
                    this.$delete(this.chat, index);
                }
            })
        })
    },
    methods: {
        isMine(state) {
            return state.from == this.user;
        },
        htmlize(string) {
            if (!string) return "";
            return sanitizeHtml(marked.parse(string));
        },
        whois(uid) {
            if (uid == this.master.id) return this.master;
            const result = this.member.find((mem) => mem.id == uid);
            if (result) return result;
            return {
                name: "存在しないメンバー",
                color: this.color,
                icon: "404"
            }
        },
        parseDate(timestamp) {
            return moment(timestamp.toDate()).format("YYYY/MM/DD HH:mm");
        },
        autoDark(color) {
            if (!color) return "";
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
        showEdit(key) {
            this.editTarget = key;
            this.dialogText = this.chat[key].content;
            this.dialog = true;
        },
        async submitEdit() {
            if (this.dialogText == this.chat[this.editTarget].content) {
                this.dialog = false;
                return;
            }
            console.log(this.chat[this.editTarget])
            await updateDoc(doc(this.$db, "group", this.id, "chat", this.chat[this.editTarget].key), {
                content: this.dialogText,
                modified: new Date()
            });
            this.dialog = false;
        },
        async checkRead() {
            for (var index in this.chat) {
                const state = this.chat[index]
                if (!state.read || !state.read.includes(this.user)) {
                    await updateDoc(doc(this.$db, "group", this.id, "chat", state.key), {
                        read: arrayUnion(this.user)
                    });
                }
            }
        }
    }
}
</script>
<style scoped>
.prewrap {
    white-space: pre-wrap;
}
.v-application p {
    margin: 0;
}
</style>