<template>
    <div>
        <v-container fill-height>
            <v-card max-width="400px" class="mx-auto my-16">
                <v-card-actions>
                    <v-col>
                    <v-btn block @click="loginGoogle" color="amber" class="my-2 px-7">
                        Googleでログイン
                    </v-btn>
                    <v-btn block @click="loginTwitter" color="light-blue" class="my-2 px-7">
                        Twitterでログイン
                    </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-overlay :value="loading" opacity="100" color="white">
            <v-progress-circular color="primary" indeterminate size="42"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { getAuth, signInWithRedirect, GoogleAuthProvider, TwitterAuthProvider, onAuthStateChanged } from "firebase/auth";

const googleProvider = new GoogleAuthProvider;
const twitterProvider = new TwitterAuthProvider;

export default {
    mounted() {
        const auth = getAuth();
        onAuthStateChanged(auth, () => {
            this.loading = false;
        });
    },

    data() {
        return {
            loading: true
        }
    },

    methods: {
        loginGoogle() {
            const auth = getAuth();
            signInWithRedirect(auth, googleProvider).then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                console.log(token);
                console.log(user);
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const credential = GoogleAuthProvider.credentialFromError(error);
                alert(errorCode + " " + errorMessage);
                console.log(credential);
            });
        },
        loginTwitter() {
            const auth = getAuth();
            signInWithRedirect(auth, twitterProvider).then((result) => {
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                console.log(token);
                console.log(user);
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorCode + " Detail: " + errorMessage);
                console.log(error);
            });
        }
    }
}
</script>
