<template>
  <v-app>
    <v-navigation-drawer app clipped temporary v-model="menuOpen">
      <Menu />
    </v-navigation-drawer>
      <Header @nav="menuOpen = !menuOpen" />
    <v-main app>
      <v-container class="ma-0 pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/header.vue';
import Menu from './components/menu.vue';
import ja from 'vuetify/lib/locale/ja'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from '@firebase/firestore';

export default {
  name: 'App',

  components: {
    Header,
    Menu
  },

  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (this.$route.path == '/login') {
        this.$router.push({
          name: "top"
        });
        }
      } else {
        if(this.$route.path != '/login') {
        this.$router.push({
          name: "login"
        });
        }
      }
      this.checkUser(user);
    })
  },

  data: () => ({
    menuOpen: false
  }),

  methods: {
    async checkUser(user) {
      let snap = await getDoc(doc(this.$db, "user", user.uid));
      if (!snap.exists()) {
        await setDoc(doc(this.$db, "user", user.uid), {
          color: this.$vuetify.theme.themes.light.primary,
          dark: false,
          name: user.displayName,
          icon: user.photoURL
        });
        snap = await getDoc(doc(this.$db, "user", user.uid))
      }
      const data = snap.data();
      this.$vuetify.lang.locales = {ja};
      this.$vuetify.lang.current = "ja";
      this.$vuetify.theme.themes.light.primary = data.color;
      this.$vuetify.theme.themes.dark.primary = data.color;
      this.$vuetify.theme.dark = data.dark;
    }
  }
};
</script>
