<template>
    <v-main>
        <div class="text-h5 ma-3">個人設定</div>
        <v-form class="mx-4" ref="form">
            <v-row class="px-3">
                <v-menu v-model="colorOpen" :close-on-content-click="false" offset-y>
                            <template v-slot:activator="{on, attrs}">
                                <v-avatar :color="color" size="40" class="my-3 mr-4" v-bind="attrs" v-on="on"></v-avatar>
                            </template>
                            <v-color-picker v-model="color" hide-mode-switch mode="hexa" show-swatches></v-color-picker>
                        </v-menu>
            <v-text-field required v-model="name" label="ユーザ名" ref="name" :rules="requireRules"></v-text-field>
            </v-row>
            <v-row class="px-3" align="end">
                <v-avatar :color="color" size="80" class="mr-3 mb-4"><v-img :src="url"></v-img></v-avatar>
                <v-file-input label="アイコン画像" v-model="image" accept="image/*" prepend-icon="mdi-image-area" :rules="fileRules"></v-file-input>
            </v-row>
            <v-row class="px-3" align="center"><v-switch class="mr-4" :color="color" v-model="dark"></v-switch> ダークテーマを使用する</v-row>
            <div class="pa-3 pb-6" justify="start" v-if="!isGoogle">
                <v-img height="46px" width="191px" v-ripple contain src="../assets/google_link.png" @click="linkGoogle"></v-img>
            </div>
            <v-row class="pa-3 pb-6" v-else align="end">
                <v-btn disabled>Google連携済み</v-btn>
                <span class="caption px-5">{{googleAddress}}と連携中</span>
            </v-row>
            <v-row class="pa-3">
                <v-btn :color="color" @click="submit" :dark="autoDark(color)">保存</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="deleteAccount">アカウント削除</v-btn>
            </v-row>
        </v-form>
    </v-main>
</template>
<script>
import { getAuth, GoogleAuthProvider, linkWithRedirect, onAuthStateChanged, reauthenticateWithPopup, TwitterAuthProvider } from '@firebase/auth';
import { deleteDoc, doc, getDoc, setDoc } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';

export default {
    mounted() {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.user = user.uid;
                this.isGoogle = user.providerData.some(data => data.providerId == "google.com");
                if (this.isGoogle) {
                    const googleData = user.providerData.find(data => data.providerId == "google.com");
                    this.googleAddress = googleData.email;
                }
                this.checkData();
            } else {
                this.$router.push("/login");
            }
        });
    },
    data() {
        return {
            user: "",
            name: "",
            color: this.$vuetify.theme.themes.light.primary,
            image: null,
            imageUrl: "",
            dark: false,
            colorOpen: false,
            isGoogle: true,
            googleAddress: "",
            requireRules: [ v => !!v || `入力してください`],
            fileRules: [value => !value || value.size <= 1024000 || "画像サイズは1MBまでです"],
            colorRules: v => /^#[0123456789ABCDEFabcdef]{6}$/.test(v),
        }
    },
    computed: {
        url() {
            if (this.image) {
                return URL.createObjectURL(this.image);
            } else if (this.imageUrl != "") {
                return this.imageUrl;
            } else {
                return "404";
            }
        },
    },
    methods: {
        async checkData() {
            const snapshot = await getDoc(doc(this.$db, "user", this.user));
            const data = snapshot.data();
            this.name = data.name;
            this.color = data.color;
            this.dark = data.dark;
            this.imageUrl = data.icon;
        },
        linkGoogle() {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            linkWithRedirect(auth.currentUser, provider).then(
                console.log(auth.currentUser)
            ).catch(err => console.log(err));
        },
        async submit() {
            if (this.$refs.form.validate() && this.colorRules(this.color)) {
                if (this.image != null) {
                    const ex = this.image.type.split("/")[1];
                    const storageRef = ref(this.$storage, "user_icon/" + this.user + "." + ex);
                    await uploadBytes(storageRef, this.image);
                    this.imageUrl = await getDownloadURL(storageRef);
                }
                await setDoc(doc(this.$db, "user", this.user), {
                    name: this.name,
                    color: this.color,
                    icon: this.imageUrl,
                    dark: this.dark
                });
                location.assign("/")
            }
        },
        async deleteAccount() {
            const yes = confirm("アカウントを削除します。この操作は取り消せません。\nよろしいですか？");
            if (!yes) return;
            const again = confirm("本当に削除しますか？\nよろしければ削除対象のアカウントを再認証してください。");
            if (!again) return;
            await deleteDoc(doc(this.$db, "user", this.user));
            const user = getAuth().currentUser;
            console.log(user);
            let provider;
            if (user.providerData[0].providerId == "google.com") provider = new GoogleAuthProvider;
            else provider = new TwitterAuthProvider;
            await reauthenticateWithPopup(user, provider).then(function() {
                user.delete();
            });
        },
        autoDark(color) {
            if (!color) return "";
            const red = parseInt(color.slice(1, 3), 16);
            const green = parseInt(color.slice(3, 5), 16);
            const blue = parseInt(color.slice(5, 7), 16);
            const luminance = ( red * 299 + green * 587 + blue * 114 ) / 1000
            if (luminance > 192) return false;
            else return true;
        },
    }
}
</script>