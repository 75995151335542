<template>
    <v-sheet outlined class="py-4">
        <v-tabs-items v-model="tab" v-if="isMaster">
            <v-tab-item eager key="master">
                <chatWindow :id="id" :color="color" :master="master" :member="member" :user="user" tab="master" @receive="receive" ref="master_chat"></chatWindow>
            </v-tab-item>
            <v-tab-item eager v-for="mem in member" :key="mem.id">
                <chatWindow :id="id" :color="color" :master="master" :member="member" :user="user" :tab="mem.id" @receive="receive" :ref="mem.id + '_chat'"></chatWindow>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="tab" v-else>
            <v-tab-item eager key="master">
                <chatWindow :id="id" :color="color" :master="master" :member="member" :user="user" tab="master" @receive="receive" ref="master_chat"></chatWindow>
            </v-tab-item>
            <v-tab-item eager :key="me.id">
                <chatWindow :id="id" :color="color" :master="master" :member="member" :user="user" :tab="me.id" @receive="receive" :ref="me.id + '_chat'"></chatWindow>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs v-model="tab" v-if="isMaster" :color="color">
            <v-tab class="master_tab" key="master" @click="checkRead('master')">全体</v-tab>
            <v-tab :class="`${mem.id}_tab`" v-for="mem in member" :key="mem.id" @click="checkRead(mem.id)">{{mem.name}}</v-tab>
        </v-tabs>
        <v-tabs v-model="tab" v-else :color="color">
            <v-tab class="master_tab" key="master" @click="checkRead('master')">全体</v-tab>
            <v-tab :class="`${me.id}_tab`" :key="me.id" @click="checkRead(me.id)">{{me.name}}</v-tab>
        </v-tabs>
        <v-form><v-container>
        <v-row>
            <v-col cols="8" sm="9" md="10">
                <v-img v-if="file" max-height="120" contain :src="url">
                    <v-btn absolute icon right top @click="file = null"><v-icon>mdi-close</v-icon></v-btn>
                </v-img>
                <v-textarea v-else v-model="inputText" rows="3" no-resize ref="input"
            @keydown.enter.exact="keyDownEnter" @keyup.enter.exact="keyUpEnter" @keydown.enter.shift="keyEnterShift"></v-textarea></v-col>
            <v-col align-self="end" class="mb-5" cols="4" sm="3" md="2">
                <v-container><v-row no-gutters><v-col cols="4"><v-file-input hide-input accept="image/*" prepend-icon="mdi-image" v-model="file" :rules="fileRules">
                </v-file-input></v-col>
                <v-col cols="8" align-self="end"><v-switch icon small v-model="readText" :color="color">
                    <template v-slot:label><v-icon>mdi-message-bulleted</v-icon></template></v-switch></v-col></v-row>
                <v-row no-gutters><v-col cols="4" align-self="center"><v-btn icon small @click.stop="submitChat" :color="color"><v-icon>mdi-send</v-icon></v-btn></v-col>
                <v-col cols="8"><v-switch icon small v-model="voice" :color="color">
                    <template v-slot:label><v-icon>mdi-microphone</v-icon></template></v-switch></v-col></v-row>
            </v-container></v-col>
        </v-row>
        </v-container></v-form>
    </v-sheet>
</template>
<script>
import { addDoc, arrayUnion, collection, doc, updateDoc } from '@firebase/firestore'
import chatWindow from './chat_window.vue'
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage'

export default {
    components: {
        chatWindow
    },
    props: [
        "id",
        "user",
        "color",
        "master",
        "member"
    ],
    data() {
        return {
            tab: 0,
            inputText: "",
            file: null,
            readText: false,
            voice: false,
            keyCode: 0,
            composing: true,
            imageUrl: "",
            sound: new Audio(require("../assets/message1.mp3")),
            speak: new SpeechSynthesisUtterance(),
            fileRules: [value => !value || value.size <= 1024000 || "画像サイズは1MBまでです"],
        }
    },
    computed: {
        isMaster() {
            return this.user == this.master.id;
        },
        me() {
            var me = this.member.find(mem => mem.id == this.user);
            if (!me) return this.master;
            me.index = this.member.findIndex(mem => mem.id == this.user);
            return me;
        },
        url() {
            if (!this.file) return "";
            return URL.createObjectURL(this.file);
        }
    },
    mounted() {
        this.speak.lang = "ja-JP";
    },
    methods: {
        async submitChat() {
            if (!this.inputText && !this.file) return;
            var tab;
            if (this.isMaster) {
                if (this.tab == 0) tab = "master";
                else tab = this.member[this.tab-1].id;
            } else {
                if (this.tab == 0) tab = "master";
                else tab = this.me.id
            }
            if (this.file) {
                if (this.file.size > 1024000) {
                    alert("画像サイズは1MBまでです。");
                    return;
                }
                const ex = this.file.type.split("/")[1];
                const storageRef = ref(this.$storage, "chat_image/" + this.id + "/" + this.hashname() + "." + ex);
                await uploadBytes(storageRef, this.file);
                this.imageUrl = await getDownloadURL(storageRef);
                addDoc(collection(this.$db, "group", this.id, "chat"), {
                    image: this.imageUrl,
                    created: new Date(),
                    from: this.user,
                    tab: tab,
                    read: []
                });
            } else {
            addDoc(collection(this.$db, "group", this.id, "chat"), {
                content: this.inputText,
                created: new Date(),
                from: this.user,
                tab: tab,
                read: []
            });
            }
            this.inputText = "";
            this.file = null;
            this.$nextTick(function() {this.$refs.input.focus()});
        },
        async receive(message) {
            const looking = this.tab == 0 ? "master" : this.member[this.tab-1].id;
            if (!message.read) {
                if (message.from != this.user) this.sound.play();
                if (message.tab == looking) {
                    await updateDoc(doc(this.$db, "group", this.id, "chat", message.id), {
                        read: arrayUnion(this.user)
                    });
                } else {
                    document.querySelector(`.${message.tab}_tab`).classList.add("unread");
                }
                if(this.readText) {
                    this.readChat(message.content, message.tab);
                }
            }
        },
        checkRead(tab) {
            document.querySelector(`.${tab}_tab`).classList.remove("unread");
            if (tab == "master" || !this.isMaster) this.$nextTick(function() {this.$refs[tab + "_chat"].checkRead()});
            else this.$nextTick(function() {this.$refs[tab + "_chat"][0].checkRead()});
        },
        readChat(content, tab) {
            if (tab != "master") {
                content = "秘話。" + content;
            }
            this.speak.text = this.readConvert(content);
            speechSynthesis.speak(this.speak);
        },
        readConvert(string) {
            return string.replace(/w+$/g, "。わらわら");
        },
        hashname() {
            return Math.random().toString(36).slice(-12);
        },
        keyDownEnter(event) {
            this.keyCode = event.keyCode;
            this.composing = event.isComposing;
            event.preventDefault();
        },
        keyUpEnter(event) {
            if(this.keyCode == 229 || this.composing) return;
            event.preventDefault();
            this.composing = true;
            this.submitChat();
        },
        keyEnterShift() {
            // デフォルト動作のため空白
        }
    },
    watch: {
        voice: function() {
            alert("ボイスチャット機能は未実装です")
        }
    }
}
</script>
<style scoped>
.unread::after {
    position:absolute;
    content: '';
    background-color: #E91E63;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    top: 6px;
    right: 6px
}
</style>